<template>
  <div>
    <div
      class="promotion-card"
      :class="bem('promotion-card', { appearance })"
      @click="isModalVisible = true"
    >
      <img
        :class="bem('promotion-card__icon', { appearance })"
        src="/images/promo/star.png"
        alt=""
      />
      <div
        class="promotion-card__info"
        :class="bem('promotion-card__info', { appearance })"
      >
        <div
          v-if="appearance === 'header' && !mediaQueries.mobile"
          class="promotion-card__title promotion-card__title--short"
          :class="bem('promotion-card__title', { appearance })"
        >
          {{ $options.promoInfo.shortTitle }}
        </div>
        <div
          v-else
          class="promotion-card__title"
          :class="bem('promotion-card__title', { appearance })"
        >
          {{ $options.promoInfo.title }}
        </div>
        <div
          class="promotion-card__description"
          :class="bem('promotion-card__description', { appearance })"
        >
          {{ $options.promoInfo.description }}
        </div>
      </div>
      <img
        :class="bem('promotion-card__button', { appearance })"
        src="/images/promo/arrow-button.png"
        alt=""
      />
    </div>
    <PromotionModal
      v-if="isModalVisible"
      :title="$options.promoInfo.title"
      :promo="promo"
      @close="isModalVisible = false"
    />
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import PromotionModal from 'src/components/PromotionModal/PromotionModal.vue';

export default {
  name: 'PromotionCard',
  components: { PromotionModal },
  promoInfo: {
    title: 'Скидка на первую вакансию',
    shortTitle: 'Скидка на вакансию',
    description: 'Акция до 22 декабря',
  },
  inject: ['mediaQueries'],
  props: {
    promo: {
      type: Object,
      required: true,
    },
    appearance: {
      type: String,
      default: 'landing',
      validator: (value) => ['landing', 'header', 'resumes'].includes(value),
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    bem,
  },
};
</script>

<template>
  <ModalWindow
    :title="title"
    appearance="new"
    @close="$emit('close', $event)"
    class="promotion-modal__wrapper"
  >
    <div class="promotion-modal">
      <div class="promotion-modal__description" v-html="modalDescription" />
      <div class="promotion-modal__actions">
        <template v-if="promo.type === 'user'">
          <BasicButton
            :href="promo.actionUrl"
            appearance="primary"
            size="l"
            rel="nofollow"
          >
            Разместить
          </BasicButton>
          <BasicButton appearance="secondary" size="l" @click="$emit('close')"
            >Отменить
          </BasicButton>
        </template>
        <template v-else>
          <BasicButton href="/users/auth/tmid" appearance="primary" size="l">
            Войти
          </BasicButton>
          <BasicButton
            href="/users/auth/tmid/register"
            appearance="secondary"
            size="l"
          >
            Зарегистрироваться
          </BasicButton>
        </template>
      </div>
    </div>
  </ModalWindow>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';

export default {
  name: 'PromotionModal',
  components: { BasicButton, ModalWindow },
  props: {
    title: {
      type: String,
      default: '',
    },
    promo: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    authRoute() {
      if (window.location.href.includes('career.habr.com')) {
        return 'https://career.habr.com';
      }

      return 'https://staging.career.habratest.net';
    },
    modalDescription() {
      if (this.promo.type === 'user') {
        return 'Разместите первую вакансию компании со скидкой до 22&nbsp;декабря.';
      }
      return 'Зарегистрируйтесь или войдите как работодатель и разместите первую вакансию компании со скидкой до 22&nbsp;декабря.';
    },
  },
};
</script>

export function checkNYPeriod() {
  const nowDate = new Date();
  const year = new Date().getFullYear();
  const startNYDate = new Date(year, 11, 1);
  const finishNYDate = new Date(year, 0, 14);
  const isNYPeriod = nowDate >= startNYDate || nowDate <= finishNYDate;
  if (isNYPeriod) {
    document.body.classList.add('ny-decor');
  }
}

document.addEventListener('DOMContentLoaded', checkNYPeriod);

import { getSsrState } from 'src/utils/ssr.js';
import { createVueApp } from 'src/utils/vue.js';

import PromotionCard from 'src/components/PromotionCard/PromotionCard.vue';

function mountPromo() {
  const elements = document.querySelectorAll('[data-promotion-card]');

  if (elements.length) {
    const ssrState = getSsrState();
    let promoVacancy;
    let appearance;
    if (!ssrState || !ssrState.promoVacancy || !ssrState.appearance) {
      promoVacancy = { type: 'guest', actionUrl: '' };
      appearance = 'header';
    } else {
      promoVacancy = ssrState.promoVacancy;
      appearance = ssrState.appearance;
    }
    elements.forEach((el) => {
      const app = createVueApp({
        render(h) {
          return h(PromotionCard, {
            props: {
              promo: promoVacancy,
              appearance,
            },
          });
        },
      });
      app.$mount(el);
    });
  }
}

document.addEventListener('DOMContentLoaded', mountPromo);
